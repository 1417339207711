import React from 'react';
import { Row, Col } from 'antd';
import AboutTile from '../../AbouTile';
import { stripTags, domHtml } from '../../../utils/stripTags';

import SEO from '../../Seo';

const pageText = {
  paraOne: `My name is Jordon Buchanan and I am a Full Stack Developer and Programmer.  I am a person who is in a constant
   quest for more programming knowledge. If it exists, i want to know it and atleast 
  come close to mastering it. When it comes to web developing my skills range from HTML5 to React with alot inbetween, CiricleCi to Kubernetes
  for DevOps, and NodeJs to Python for backend work. 
  Although there is still alot to learn, Im able to pick anything that may be required with ample enough time. Alot of 
  qualities I pride myself on are patience, organization, determination, pragmatism, analytics, and always being positive. 
  My strive for logic is one of the main reasons I've been drawn to programming ... and well to be creative too!`,
  paraTwo: ``,
};

const AboutMe = () => {
  const description = `${pageText.paraOne} ${stripTags(pageText.paraTwo)}`;
  return (
    <>
      <div>
        <SEO
          title="About"
          description={description}
          path=""
          keywords={['Jordon', 'Buchanan', 'Chicago', 'FullStack developer', 'Javascript', 'ReactJS', 'NodeJS', 'Gatsby']}
        />
        <h1 className="titleSeparate">About Me</h1>
        <p>
          {pageText.paraOne}
        </p>
        <p dangerouslySetInnerHTML={domHtml(pageText.paraTwo)} />
      </div>
      <Row gutter={[20, 20]}>
        <Col xs={24} sm={24} md={12} lg={8}>
          <AboutTile
            img="van.svg"
            height={60}
            alt="location image"
            textH4="A transplant from"
            textH3="Westminster, Maryland"
          />
        </Col>
        <Col xs={24} sm={24} md={12} lg={8}>
          <AboutTile
            img="coffee.png"
            alt="coffee image"
            textH4="Coffee Addict"
            textH3="Basically a necessity"
          />
        </Col>
        <Col xs={24} sm={24} md={12} lg={8}>
          <AboutTile
            img="history.svg"
            alt="history"
            textH4="History Buff"
            textH3="Second love"
          />
        </Col>
        <Col xs={24} sm={24} md={12} lg={8}>
          <AboutTile
            img="web.png"
            alt="web image"
            textH4="Self Taught Programmer"
            textH3="Dedication and determination"
            height={60}
            width={60}
          />
        </Col>
        <Col xs={24} sm={24} md={12} lg={8}>
          <AboutTile
            img="future.svg"
            alt="futurism"
            textH4="Futurist"
            textH3="Looking toward space"
            height={60}
            width={60}
          />
        </Col>
      </Row>
    </>
  );
};
export default AboutMe;
